import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import Layout from 'components/layout';

// Styles
import * as styles from './deep-fractional-rf.module.scss';

const deepFractionalRfPage = () => {
  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout title="Reverso by INDIBA | Deep Fractional Radiofrequency for Skin Aging Treatment" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h5 className={styles.Subtitle}>Deep Fractional RF</h5>
            <h1>Skin Resurfacing & Remodeling</h1>
            <p>
              Reverso generates confined radio frequency electric currents that are
              transmitted to the skin through an array of tiny needles.
              When the electromagnetic energy penetrates the skin,
              it is converted into high heat energy,
              causing controlled thermal destruction.
            </p>
            <p>
              The physiological impact of the Reverso deep fractional RF on the skin is twofold:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Tissue ablation </strong>
                  - Tissue destruction by vaporization, through the entire path of
                  the Reverso pins into the skin. The healing processes of this destruction
                  will result in skin resurfacing - remodulation of the epidermis,
                  up to the stratum corneum.
                </p>
              </li>
              <li>
                <p>
                  <strong>Protein coagulation </strong>
                  (up to full denaturation) - The healing processes of the resulting
                  inflammation accelerate formation of new collagen, elastin, and hyaluronic acid,
                  thus triggers dermal regeneration.
                </p>
              </li>
            </ul>
          </div>
          <div className={styles.Images}>
            <div className={styles.Image}>
              <h5>Deep & Narrow Ablation</h5>
              <StaticImage width={307} height={252} src="../../images/ablation.png" alt="Deep and Narrow Ablation" />
            </div>
            <div className={styles.Image}>
              <h5>Protein Coagulation</h5>
              <StaticImage width={230} height={249} src="../../images/coagulation.png" alt="Protein Coagulation" />
            </div>
          </div>
        </div>
        <div className={styles.Block}>
          <p>
            The healing process of an ablative thermal skin destruction,
            such as the one caused by Reverso, is shorter than that of non-ablative
            thermal destruction caused by other fractional RF devices.
          </p>
          <p>
            <strong>The required downtime is therefore relatively short.</strong>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default deepFractionalRfPage;
